import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7064660c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "product-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.searchQuery), 1),
    _createElementVNode("h5", null, _toDisplayString(_ctx.found), 1),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsFound, (p) => {
        return (_openBlock(), _createElementBlock("li", {
          key: p.id,
          class: "product-item block-grid-xs-2 block-grid-md-3 block-grid-lg-4"
        }, [
          _createVNode(_component_Card, {
            card: p,
            "card-type": 'product'
          }, null, 8, ["card"])
        ]))
      }), 128))
    ])
  ]))
}