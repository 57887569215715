
import {defineComponent} from "vue";
import apiService from "../api/api";
import Card from "@/components/Card.vue";
import PRODUCT, {initProductCard} from "@/interfaces/product";

export default defineComponent ({
  name: "Category",
  components: { Card },
  data() {
    const productsFound: PRODUCT[] = []
    return {
      productsFound,
      searchQuery: ''
    }
  },
  computed: {
    found(): string {
      let len: any = this.productsFound.length + ''
      let str = `(Найдено ${len} `
      len = len.split('')
      switch (len[len.length - 1]) {
        case '1':
          str += 'совпадение'
          break
        case '2':
        case '3':
        case '4':
          str += 'совпадения'
          break
        default:
          str += 'совпадений'
          // console.log()
      }
      return str + ')'
    }
  },
  beforeMount() {
    const query = this.$route.params['searchQuery'] as string
    this.searchQuery = query.replace(/\+/g, ' ')
  },
  mounted() {
    console.log('CATEGORY', this.$route.params['searchQuery'])
    // alert(JSON.stringify(this.$route.params))
    apiService.get('search', this.$route.params['searchQuery']).then((r: any): any => {
      console.log(r)
      if (r.data.error) return
      if (r.data.products && r.data.products.length) {
        const prodsFound = r.data.products
        for (let i = 0; i < prodsFound.length; i++) {
          this.productsFound.push(initProductCard(prodsFound[i]))
        }
      }
    })
  }
})
